"use strict";
import {on, findAllIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
export function init() {
    onFind('.js-newsletter-form-tracking', function (form) {
        let topics = findAllIn('.js-newsletter-form-tracking__topic input', form);
        let topicList = [];

        on('form-validation.submit', () => {
            topics.forEach((topics) => {
                if (topics.checked) {
                    console.log(topics.getAttribute('data-newsletter-form-tracking-topic'))
                    topicList.push(topics.getAttribute('data-newsletter-form-tracking-topic'));
                }
            })

            window.dataLayer.push({
                'event': 'newsletter_sign_up',
                'topic': topicList
            });

            form.submit();
        }, form);
        
    });
}