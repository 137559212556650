import {onFind} from "@elements/init-modules-in-scope";
import {findIn, findAllIn, closest, addClass, removeClass, on, find, hasClass} from "@elements/dom-utils";
import Collapse from 'bootstrap/js/dist/collapse';
import { getPrefixedDataSet } from '@elements/data-set-utils';
import asyncAppend from "@elements/async-append";
import axios from "axios";
let body = document.body;

export function init() {
    //navbar main
    onFind('.js-navbar', (container) => {
        let toggle = findIn('.js-navbar__toggle', container);
        let nav = findIn('.js-navbar__main', container);
        let close = findIn('.js-navbar__close', container);
        let items = findAllIn('.js-navbar__item', container);
        let levels = findAllIn('.js-navbar__level-two', container);
        let levelTwoItems = findAllIn('.js-navbar__level-two-item', container);
        let collapseList = [];

        on('click', (e) => {
             addClass('is-active', nav);
            addClass('has-overlay', body);
            body.addEventListener('click', bodyClickHandler);
        }, toggle);

        on('click', (e) => {
            removeClass('is-active', nav);
            removeClass('has-overlay', body);
            body.removeEventListener('click', bodyClickHandler);
        }, close);

        //mobile collapse
        if(window.matchMedia("(max-width: 767px)").matches) {
            const collapseArray = [];
            levels.map(level => {
                collapseArray.push(closest('.js-navbar-main__level-two-collapse', level));
            });
            collapseArray.map(item => {
                addClass('collapse', item);
            })
            collapseList = [...collapseArray].map(collapseEl => new Collapse(collapseEl, {
                toggle: false
            }));
        }

        levelTwoItems.map((item) => {
            if(hasClass('is-active', item)){
                let level = closest('.js-navbar__level-two', item);
                addClass('is-active', level);

                if(window.matchMedia("(max-width: 767px)").matches) {
                    let collapse = Collapse.getOrCreateInstance(closest('.js-navbar-main__level-two-collapse', level));
                    collapse.show();
                }
            }
        });

        items.map((item) => {
             let parent = closest('li', item);
             let level = findIn('.js-navbar__level-two', parent);
             let collapse;
            if(window.matchMedia("(max-width: 767px)").matches) {
                collapse = Collapse.getOrCreateInstance(closest('.js-navbar-main__level-two-collapse', level));
            }

            on('click', (e) => {
                if(hasClass('is-active', item)){
                    removeClass('has-collapse', nav);
                    removeClass('is-active', item);
                    if(window.matchMedia("(max-width: 767px)").matches) {
                        collapse.toggle();
                    }
                }else{
                    levels.map(element => {
                        removeClass('is-active', element);
                    });
                    items.map(element => {
                        removeClass('is-active', element);
                    });

                    //toggle collapse mobile
                    if(window.matchMedia("(max-width: 767px)").matches) {
                        collapseList.map(element => {
                            element.hide();
                        });
                        collapse.toggle();
                    }

                    addClass('is-active', level);
                    addClass('is-active', item);
                    addClass('has-collapse', nav);
                }
             }, item);
        });
    });

    //navbar time
    onFind('.js-navbar-time', (container) => {
        let toggle = findIn('.js-navbar-time__toggle', container);
        let overlay = findIn('.js-navbar-time__overlay', container);
        let close = findIn('.js-navbar-time__close', container);

        on('click', (e) => {
            addClass('is-active', overlay);
            addClass('has-overlay', body);
            if(!hasClass('request-success', container)){
                navbarTimeAsync(container);
            }
            body.addEventListener('click', bodyClickHandlerTime);
        }, toggle);
        on('click', (e) => {
            removeClass('is-active', overlay);
            removeClass('has-overlay', body);
            body.removeEventListener('click', bodyClickHandlerTime);
        }, close);
    });

    //logo transform
    onFind('.js-navbar-brand', (container) => {
        window.addEventListener('scroll', (e) => {
            if(window.scrollY > 300){
                addClass('is-active', container);
            }else{
                removeClass('is-active', container);
            }
        }, {passive: true});
    });
}

function bodyClickHandler(e){
    if(!closest('.js-navbar', e.target)){
        removeClass('is-active', find('.js-navbar__main'));
        removeClass('has-overlay', body);
        body.removeEventListener('click', bodyClickHandler);
    }

}

function bodyClickHandlerTime(e){
    if(!closest('.js-navbar-time', e.target)){
        removeClass('is-active', find('.js-navbar-time__overlay'));
        removeClass('has-overlay', body);
        body.removeEventListener('click', bodyClickHandlerTime);
    }

}

function navbarTimeAsync(container) {
    let async = findIn('.js-navbar-time__async', container);
    let loading = findIn('.js-navbar-time__async-loading', container);
    let notifications = findIn('.js-navbar-time__async-notifications', container);
    let result = findIn('.js-navbar-time__async-result', container);
    let dataset = getPrefixedDataSet('navbar-time', async);

    //loading
    let pendingRequest = axios({method: 'POST', url: dataset.url, headers: {'X-Requested-With': 'XMLHttpRequest'} });

    asyncAppend({
        target: result,
        loading: loading,
        notification: notifications
    }, pendingRequest.then((x) => {
        return x.data;
    })).then(y => {
        addClass('request-success', container);
    });
}