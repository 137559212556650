"use strict";
import {on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
export function init() {
    /*input with event and term*/
    onFind('.js-filter-form-tracking .js-filter-form-tracking__term', function (input) {
        if (input) {
            on('change', function () {
                window.dataLayer.push({
                    'event': input.getAttribute('data-filter-form-tracking-name'),
                    'term': input.value
                });
            }, input);
        }
    });

    /*select or checkbox with event, type and value*/
    onFind('.js-filter-form-tracking .js-filter-form-tracking__input', function (input) {
        if (input) {
            on('change', function (event) {
                if(input.getAttribute('type') === 'checkbox') {
                    if (event.target.checked) {
                        window.dataLayer.push({
                            'event': 'select_filter',
                            'filter_type': input.getAttribute('data-filter-form-tracking-type'),
                            'filter_value': input.getAttribute('data-filter-form-tracking-value')
                        });
                    }
                } else {
                    window.dataLayer.push({
                        'event': 'select_filter',
                        'filter_type': input.getAttribute('data-filter-form-tracking-type'),
                        'filter_value': input.options[input.selectedIndex].text
                    });
                }
            }, input);
        }
    });
}