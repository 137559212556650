import {add} from '@elements/scroll-animations';
import {onFind} from '@elements/init-modules-in-scope';

export function init() {
    onFind('.js-parallax', (element) => {
        let query =  element.getAttribute('data-parallax-query') || '(max-width: 767px)';

        if (matchMedia(query).matches){
            return;
        }

        let elements = [];
        elements.push(element);

        add(
            elements,
            calculateAnimationProgress,
            setAnimationProgress
        );
    });

}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 4 * 3;
    const end = -300;

    return 1 - Math.max(
        Math.min(
            (element.getBoundingClientRect().top - end) / (start - end),
            1
        ),
        0
    );
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    let parallaxValue = element.getAttribute('data-parallax-value') || 20;
    let parallaxProgress = progress * parallaxValue;

    element.style.transform = "translateY(" + parallaxProgress + "px)";
}
