"use strict";

import {getPrefixedDataSet} from "@elements/data-set-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {findIn, findAllIn, removeClass, addClass, on, hasClass, setAttribute, trigger, removeAttribute} from '@elements/dom-utils';
import {add} from "@elements/scroll-animations";
import {onEnterViewPort} from "@elements/viewport-utils";

const defaultSelectors = {
    base: '.js-rotation-slider',
    circle: '.js-rotation-slider__circle',
    dot: '.js-rotation-slider__dot',
    item: '.js-rotation-slider__item',
    image: '.js-rotation-slider__image',
    content: '.js-rotation-slider__content',
    arrowPrev: '.js-rotation-slider__arrow-prev',
    arrowNext: '.js-rotation-slider__arrow-next',
};

export function init (selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        onEnterViewPort(baseElement, () => {
            document.body.addEventListener('ASYNC.APPEND', (e) => {
                setTimeout(() => {
                    onFind(selectors.base, function (baseElement) {
                        createRotationSlider(
                            baseElement,
                            {...defaultSelectors, ...selectors}
                        );
                    });
                }, 100);
            }, { once: true});
        });
    });
}

export function createRotationSlider( baseElement, selectors){
    let circle = findIn(selectors.circle, baseElement );
    let dots = findAllIn(selectors.dot, baseElement );
    let items = findAllIn(selectors.item, baseElement );
    let images = findAllIn(selectors.image, baseElement );
    let contents = findAllIn(selectors.content, baseElement );
    let arrowPrev = findIn(selectors.arrowPrev, baseElement );
    let arrowNext = findIn(selectors.arrowNext, baseElement );

    const circleStyles = window.getComputedStyle(circle);
    let defaultRotation = parseInt(circleStyles.getPropertyValue('--rotation-slider-rotation'));
    let angle = parseInt(circleStyles.getPropertyValue('--rotation-slider-angle'));
    let circleSize = parseFloat(circleStyles.getPropertyValue('--rotation-slider-circle-size'));

    //initial Calculation
    calculateRotation(defaultRotation, circleSize, angle, dots);
    addClass('is-active', dots[0]);
    
    dots.map((dot, i) => {
        //recalculate circle and show next slider item on click
        on('click', (e) => {
            dots.map(element => {
                removeClass('is-active', element);
                removeAttribute('aria-current', element);
            });
            addClass('is-active', dot);
            setAttribute('aria-current', 'true', dot);
            calculateRotation(defaultRotation - i * angle, circleSize, angle, dots);
            
            const data = getPrefixedDataSet('rotation-slider', dot);
            items.map(item => {
                const itemData = getPrefixedDataSet('rotation-slider', item);
                if(hasClass('is-active', item)){
                    removeClass('is-active', item);
                }
                if(itemData.id === data.id){
                    addClass('is-active', item);
                }
            });

            contents.map(item => {
                const itemData = getPrefixedDataSet('rotation-slider', item);
                if(hasClass('is-active', item)){
                    removeClass('is-active', item);
                }
                if(itemData.id === data.id){
                    addClass('is-active', item);
                }
            });

            removeAttribute('disabled', arrowPrev);
            removeAttribute('disabled', arrowNext);
        }, dot);
    });

    if(arrowPrev !== null && arrowNext !== null) {
        on('click', (e) => {
            for (let i = 0; i < dots.length; i++) {
                if (hasClass('is-active', dots[i])) {
                    const id = getPrefixedDataSet('rotation-slider', dots[i]).id;
                    if (id < 2) {
                        setAttribute('disabled', 'disabled', arrowPrev);
                    } else {
                        removeAttribute('disabled', arrowNext);
                        trigger('click', dots[id - 2]);
                        break;
                    }
                    break;
                }
            }
        }, arrowPrev);

        on('click', (e) => {
            for (let i = 0; i < dots.length; i++) {
                if (hasClass('is-active', dots[i])) {
                    const id = getPrefixedDataSet('rotation-slider', dots[i]).id;
                    if (id >= dots.length) {
                        setAttribute('disabled', 'disabled', arrowNext);
                    } else {
                        removeAttribute('disabled', arrowPrev);
                        trigger('click', dots[id]);
                        break;
                    }
                    break;
                }
            }
        }, arrowNext);
    }

    //paralax animation
    add(
        images,
        calculateAnimationProgress,
        setAnimationProgress
    )
}

function calculateRotation(defaultRotation, circleSize, angle, dots){
    let tmpRotation = 0;
    dots.map((dot, i) => {
        if(i === 0){
            tmpRotation = defaultRotation;
        }

        let translate = circleSize/2;
        dot.style.transform = `rotate(${tmpRotation}deg) translate(${translate}rem) rotate(${tmpRotation*-1}deg) translate(-50% , -50%)`;
        tmpRotation = tmpRotation + angle;
    });
}

// gets element -> radius for clippath animation and end is for stopping the animation after 1200px to reduce cpu load
function calculateAnimationProgress(element) {
    const start = 200
    let end =  0

    return {
        start: window.innerHeight,
        value: window.matchMedia("(min-width: 768px)").matches ? element.getBoundingClientRect().top / 7 : element.getBoundingClientRect().top / 20,
        end: 0
    }
}

// gets element & progress
function setAnimationProgress(element, progress) {
    if(element.getBoundingClientRect().top > progress.end && element.getBoundingClientRect().top < progress.start){
        element.style.top = progress.value + 'px';
    }
}