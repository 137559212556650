import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-drag', function (dragElement) {
        if (matchMedia('(min-width: 768px)').matches && !_config.editmode) {
            createDragElement(dragElement);
        }
    });
}

function createDragElement(dragElement) {
    let isDown = false;
    let startX;
    let scrollLeft;

    dragElement.addEventListener('mousedown', (e) => {
        isDown = true;
        dragElement.classList.add('active');
        startX = e.pageX - dragElement.offsetLeft;
        scrollLeft = dragElement.scrollLeft;
    });
    dragElement.addEventListener('mouseleave', () => {
        isDown = false;
        dragElement.classList.remove('active');
    });
    dragElement.addEventListener('mouseup', () => {
        isDown = false;
        dragElement.classList.remove('active');
    });
    dragElement.addEventListener('mousemove', (e) => {
        if(!isDown) return;
        e.preventDefault();
        const x = e.pageX - dragElement.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        dragElement.scrollLeft = scrollLeft - walk;
    });
}
