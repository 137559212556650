"use strict";
import {on, findIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
export function init() {
    onFind('.js-contact-form-tracking', function (form) {
        let topic = findIn('.js-contact-form-tracking__topic', form);

        on('form-validation.submit', () => {
            window.dataLayer.push({
                'event': 'enquiry_sent',
                'topic': topic.options[topic.selectedIndex].text
            });
            form.submit();
        }, form);

    });
}