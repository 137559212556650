"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,on} from '@elements/dom-utils';

const defaultOptions = {};

const defaultSelectors = {
    base: '.js-content-visibility',
    styleClass: 'content-visibility--auto'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createContentVisibility(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createContentVisibility(item, options, selectors) {
    on('load', function() {
        removeClass(selectors.styleClass, item);
    }, window);
}
