"use strict";
import {on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
export function init() {
    /*range slider min change*/
    onFind('.js-range-slider-tracking .js-range-slider__min', function (inputMin) {
        if (inputMin) {
            on('change', function () {
                window.dataLayer.push({
                    'event': 'select_filter',
                    'filter_type': inputMin.getAttribute('data-range-slider-tracking-name'),
                    'filter_value': inputMin.value
                });
            }, inputMin);
        }
    });


    /*range slider max change*/
    onFind('.js-range-slider-tracking .js-range-slider__max', function (inputMax) {
        if (inputMax) {
            on('change', function () {
                window.dataLayer.push({
                    'event': 'select_filter',
                    'filter_type': inputMax.getAttribute('data-range-slider-tracking-name'),
                    'filter_value': inputMax.value
                });
            }, inputMax);
        }
    });
}