// Todo: Add common JS that is shared between all page types here
import {onFind} from '@elements/init-modules-in-scope';
import {on, trigger, findIn} from '@elements/dom-utils';

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as tracking from "@elements/tracking";
let trackingOptions = {}; // optional options
tracking.initGtmEventTracking(trackingOptions); // for GTM tracking

import * as filterFormTracking from './filter-form-tracking';
filterFormTracking.init();

import * as contactFormTracking from './contact-form-tracking';
contactFormTracking.init();

import * as newsletterFormTracking from './newsletter-form-tracking';
newsletterFormTracking.init();

import * as rangeSliderTracking from './range-slider-tracking';
rangeSliderTracking.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init({
    preventNative: true
});

import * as smoothScroll from "@elements/smooth-scroll";
smoothScroll.init({
    scrollOffset: 200
});

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as lightbox from '@elements/lightbox';
lightbox.init({
    controls: true,
    showCloseIcon: true,
    mobileSettings: { controls: true, showCloseIcon: true, allowMediaOverlap: false }
});

import * as Console from './console';
Console.init();

import * as cartCount from './cart-count';
cartCount.init();

import * as reloadForm from '@elements/reload-form';
reloadForm.init();

import * as hashCash from './hashCash';
hashCash.init();

import * as formValidation from '@elements/form-validation';
formValidation.init({
    trigger: {event: 'false'}
});
onFind('.js-form-validation.js-ajax-form__form', element => {
    on('form-validation.submit', (e) => {
        trigger('submit', element);
    }, element);
});

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init({arrowIconClass: 'icon icon-arrow-long'});

import * as dragScrolling from './drag-scrolling';
dragScrolling.init();

import * as rotationSlider from './rotation-slider';
rotationSlider.init();

import * as asyncAppendViewport from './async-append-viewport';
asyncAppendViewport.init();

import * as parallax from './parallax';
parallax.init();

import * as navbar from './navbar';
navbar.init();

import * as overlay from './overlay';
overlay.init();

import * as consentOverlay from '@elements/consent-overlay';
consentOverlay.init();

import * as affix from "@elements/affix";
affix.init({mediaQuery: '(min-width: 768px)'});

import Collapse from 'bootstrap/js/dist/collapse';
import Dropdown from 'bootstrap/js/dist/dropdown';

// import * as renderTemplate from './render-template';
// renderTemplate.init();
//
// if you use this, also use the associated CSS (content-visibility.scss)
import * as contentVisibility from './content-visibility';
contentVisibility.init();
