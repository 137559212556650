"use strict";
import {findAllIn, trigger, removeClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import axios from "axios";
import {onEnterViewPort} from "@elements/viewport-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import {initInScope} from "@elements/init-modules-in-scope";

export function init() {

    onFind('.js-async-append-viewport', function (element) {
        let dataset = getPrefixedDataSet('async-append-viewport', element);
        let target;

        if(dataset.resultSplitting === 'true'){
            target = {
                'result-one': findAllIn('.js-async-append-viewport__result-first', element),
                'result-two': findAllIn('.js-async-append-viewport__result-second', element)
            }
        }else {
            target = findAllIn('.js-async-append-viewport__result', element);
        }

        onEnterViewPort(element, () => {
            let pendingRequest = axios({method: 'POST', url: dataset.url, headers: {'X-Requested-With': 'XMLHttpRequest'} });

            asyncAppend({
                target: target,
                loading: findAllIn('.js-async-append-viewport__loading', element)
            }, pendingRequest.then((x) => {
                trigger('ASYNC.APPEND', document.body);
                return x.data;
            }));
        });
    });
}