import {addClass, setAttribute} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import axios from "axios";

export function init() {
    onFind('.js-cart-count', element => {
        let description = document.createElement('span');
        let count = document.createElement('span');
        let url = element.getAttribute('data-cart-url');

        addClass('visually-hidden', description);
        addClass('console__item-count', count);
        setAttribute('aria-hidden', 'true', count);

        if(url){
            let request = axios({
                method: 'get',
                url: url,
                headers: {'X-Requested-With': 'XMLHttpRequest'}
            });

            request.then(response => response.data)
                .then(result => {
                    if (result.cart) {
                        description.innerHTML = result.cart + ' Produkte im Warenkorb';
                        count.innerHTML = result.cart;
                        element.appendChild(description);
                        element.appendChild(count);
                    }
                }).catch(e => {
                console.warn(e);
            });
        }
    });
}