import {onFind} from "@elements/init-modules-in-scope";
import {findIn, findAllIn, hasClass, addClass, removeClass, on, off, trigger, closest} from "@elements/dom-utils";

export function init() {
    onFind('.js-overlay', (container) => {
        let toggles = findAllIn('.js-overlay__toggle', container);
        let overlay = findIn('.js-overlay__content', container);

        toggles.map(toggle => {
            on('click', (e) => {
                if(hasClass('is-open', overlay)){
                    removeClass('is-open', overlay);
                    toggles.map(item => {
                        removeClass('is-open', item);
                    });
                }else{
                    addClass('is-open', overlay);
                    toggles.map(item => {
                        addClass('is-open', item);
                    });
                }
            }, toggle);
        });
    });
}
