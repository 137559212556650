import {onFind} from "@elements/init-modules-in-scope";
import {findIn, find, findAllIn, hasClass, addClass, removeClass, on, off, trigger, closest} from "@elements/dom-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import axios from "axios";
import asyncAppend from "@elements/async-append";

let body = document.body;

export function init() {
    onFind('.js-console', (container) => {
        let toggleLive = findIn('.js-console-live__toggle', container);
        let toggleSearch = findIn('.js-console-search__toggle', container);

        if(toggleLive){
            on('click', (e) => {
                toggleLiveOverlay();
                addClass('has-overlay', body);
            }, toggleLive);
        }

        if(toggleSearch){
            on('click', (e) => {
                toggleSearchOverlay();
                addClass('has-overlay', body);
            }, toggleSearch);
        }

        if(window.matchMedia("(max-width: 1399px)").matches){
            document.addEventListener('scroll', function(e) {
                let documentHeight = document.body.scrollHeight;
                let currentScroll = window.scrollY + window.innerHeight;
                // When the user is [modifier]px from the bottom, fire the event.
                let modifier = 200;
                if(currentScroll + modifier > documentHeight) {
                    addClass('is-bottom', container);
                }else{
                    removeClass('is-bottom', container);
                }
            });
        }
    });
}

function toggleLiveOverlay(){
    onFind('.js-console-live', (container) => {
        if(hasClass('is-active', container)){
            removeClass('is-active', container);
            removeClass('has-overlay', body);
        }else{
            let close = findIn('.js-console-live__close', container);

            addClass('is-active', container);
            addClass('has-overlay', body);

            on('click', (e) => {
                removeClass('is-active', container);
                removeClass('has-overlay', body);
                body.removeEventListener('click', bodyClickHandlerLive);
            }, close);

            body.addEventListener('click', bodyClickHandlerLive);

            //loading
            let pendingRequest = axios({method: 'POST', url: getPrefixedDataSet('console-live', container).url, headers: {'X-Requested-With': 'XMLHttpRequest'} });

            asyncAppend({
                target: findAllIn('.js-console-live__result', container),
                loading: findAllIn('.js-console-live__loading', container),
                notification: findAllIn('.js-console-live__notifications', container)
            }, pendingRequest.then((x) => {
                return x.data;
            })).then(y => {
                let progressItems = findAllIn('.js-console-live__progress', container);

                progressItems.map((progress) => {
                    let progressCircle = findIn('.js-console-live__progress-circle', progress);
                    const value = getPrefixedDataSet('console-progress', progress).value;
                    const maxValue = getPrefixedDataSet('console-progress', progress).max;

                    const progressString = (Math.round((value/maxValue*100) / 5) * 5).toString();
                    setTimeout(() => {
                        addClass('circle-' + progressString, progressCircle);
                    }, 500);
                });
            });
        }
    });
}

function toggleSearchOverlay() {
    onFind('.js-console-search', (container) => {
        if(hasClass('is-active', container)){
            removeClass('is-active', container);
            removeClass('has-overlay', body);
        }else{
            let close = findIn('.js-console-search__close', container);
            let recommendations = findAllIn('.js-console-search__recommendation', container);
            let input = findIn('.js-console-search__recommendation-input', container);

            addClass('is-active', container);
            addClass('has-overlay', body);

            on('click', (e) => {
                removeClass('is-active', container);
                removeClass('has-overlay', body);
                body.removeEventListener('click', bodyClickHandlerSearch);
            }, close);

            body.addEventListener('click', bodyClickHandlerSearch);

            recommendations.map(item => {
                on('click', e => {
                    const value = item.textContent;
                    input.value = value.trim();
                    trigger('change', input);
                }, item);
            });

            input.focus();
            trigger('change', input);
        }
    });
}

function bodyClickHandlerLive(e){
    if(!closest('.js-console-live', e.target) && !closest('.js-console-live__toggle', e.target)){
        removeClass('is-active', find('.js-console-live'));
        removeClass('has-overlay', body);
        body.removeEventListener('click', bodyClickHandlerLive);
    }
}

function bodyClickHandlerSearch(e){
    if(!closest('.js-console-search', e.target) && !closest('.js-console-search__toggle', e.target)){
        removeClass('is-active', find('.js-console-search'));
        removeClass('has-overlay', body);
        body.removeEventListener('click', bodyClickHandlerSearch);
    }
}